customElements.define('gtm-loader',
  class extends HTMLElement {

    _gtmInitialized = false;

    constructor() {
      super();
      const gtmId = this.getAttribute('gtmId')
      if (!gtmId) {
        return;
      }
      if (document.location.search.includes('gtm_debug')) {
        this._initGtm(gtmId);
        return;
      }
      window.addEventListener('BcfAppLoaded', () => {
        window.addEventListener('click', () => this._initGtm(gtmId), { once: true })
        window.addEventListener('touchstart', () => this._initGtm(gtmId), { once: true })
        window.addEventListener('scroll', () => this._initGtm(gtmId), { once: true })
        // We should always initialize the _initGtm load if a hash exists in the current URL. This is necessary because, in certain scenarios, analytics actions were triggered after rendering the component via a URL fragment, for example, #modal-deposit-status, #modal-withdraw-status, etc.
        if (window.location.hash !== '') {
          this._initGtm(gtmId);
        }
        setTimeout(() => {
          this._initGtm(gtmId)
        }, 10000)
      }, { once: true })
    }



    _initGtm(gtmId) {
      if (this._gtmInitialized) {
        return;
      }
      this._gtmInitialized = true;
      (function (w, d, s, l, i, g, n) {
        w[l] = w[l] || [];
        w[g] = function () { dataLayer.push(arguments); };
        w[g]('consent', 'default', {
          'ad_storage': 'denied',
          'ad_user_data': 'denied',
          'ad_personalization': 'denied',
          'analytics_storage': 'denied',
          'personalization_storage': 'denied',
        });
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        j.onload = () => {
          w[n] = true;
          w.dispatchEvent(new CustomEvent("gtmLoaded"));
        }
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', gtmId, 'gtag', 'isGtmLazyLoaded');
    }
  }
);